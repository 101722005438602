<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form') }}</v-card-title>

          <v-card-text>{{ this.$t('_file_share._info.content') }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ this.$t('_common.OK') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addFolderDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ this.$t('_file_share.Add_New_Foler') }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-label>{{ this.$t('_file_share.Selected_Folder') }} <span style="color: orangered;">{{ this.selectedFolder ? this.selectedFolder.name : 'Root'}}</span>
                  </v-label>
                  <v-text-field
                    v-model="newFolder.FolderName"
                    :label= 'this.$t("_file_share.Folder_Name")'
                    flat
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline">
                  </v-text-field>
                  <br/>
                  <v-btn color="blue darken-1" @click="createFolder" class="primary float-right">{{ this.$t('_file_share.Create_Folder') }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn color="blue darken-1" text @click="addFolderDialog = false">{{ this.$t('_common.Cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <v-container v-if="!isRegisteredUser" class="mx-0 py-0 col-12">
      <v-row>
        <v-col>
          <v-btn @click="init" type="button" class="mr-2" color="primary">{{ this.$t('_file_share.Register') }} </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="isRegisteredUser" class="mx-0 py-0 col-12">
      <v-row>
        <v-col>
          <v-card scrollable persistent>
            <v-card>
              <v-card-title>{{ this.$t('_file_share.File_Upload') }} </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="overflow:hidden;">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-file-input
                      :label= 'this.$t("_file_share.Please_select_a_file")'
                      accept="/*"
                      v-model="file"
                      multiple
                    />
                  </v-col>
                  <v-col>
                    <v-btn @click="uploadFile" type="button" style="float:right" class="mr-2" color="primary">{{ this.$t('_file_share.Upload') }}
                    </v-btn>
                    <v-overlay :value="progress > 0">
                    <v-progress-circular size="100" :value="progress">{{progress === 100 ? 'Loading...' : `${progress}%`}}</v-progress-circular>
                    </v-overlay>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <!--<v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="seenFileContent = false" class="mr-2">Close</v-btn>
              </v-card-actions>-->
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="isRegisteredUser" class="mx-0 py-0 col-12">

      <v-row>
        <v-col cols="12" sm="12" md="3">
          <v-card>
            <v-sheet class="px-4 pt-2">
              <v-row>
                <v-col cols="12">
                  <v-btn @click="addFolderDialog = true" class="primary float-right col-12">{{ this.$t('_file_share.Add_Folder') }}</v-btn>
                </v-col>
                <v-col class="col-12">
                  <v-text-field
                    v-model="search"
                    :label= 'this.$t("_file_share.Search_Folder")'
                    flat
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-sheet>
            <v-card-text style="height: 416px; overflow: auto;">
              <v-treeview
                ref="treeViewFolders"
                :items="treeFileData"
                :search="search"
                v-model="treeFileDataModel"
                item-children="childFolders"
                activatable
                multiple="false"
                selection-type="leaf"
                item-key="id"
                color="accent"
                dense
                item-text="name"
                @update:active="onSelectedFile"
                style="min-width: fit-content;"
              ></v-treeview>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="removeFolder" :disabled="!selectedFolder">{{ this.$t('_common.Remove') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="9">
          <v-card>
            <v-sheet class="px-4 pt-2">
              <v-text-field
                v-model="input2"
                v-model.lazy="searchWord"
                :append-outer-icon="'search'"
                @click:append-outer="searchFile"
                :label= 'this.$t("_file_share.Search_File")'
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                v-on:keyup.enter="searchFile()"
              ></v-text-field>
            </v-sheet>
            <v-data-table
              :headers="headers"
              :items="indexedItems"
              v-model="fileDataModel"
              :server-items-length="totalItems"
              :options.sync="options"
              :page.sync="options.pagination.page"
              :items-per-page.sync="options.pagination.itemsPerPage"
              :loading="tableLoading"
              class="elevation-1"
              show-select
              :footer-props="options.pagination.footerProps"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @click:row="rowClick"
              height="490"
            >
              <template v-slot:item.fileOwner="{ item }">
                <v-chip :color="customRowClass(item.fileOwner)" dark><i v-if="!item.fileOwner" class="material-icons"> folder_shared </i><i v-if="item.fileOwner && !item.isFileShared" class="material-icons"> folder </i> <i v-if="item.fileOwner && item.isFileShared" class="material-icons"> folder_special </i></v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom v-if="item.fileOwner">
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="removeFile(item)" v-on="on" class="mr-2">delete</v-icon>
                  </template>
                  <span>{{$t("_common.Delete")}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="downloadFile(item)" v-on="on">mdi-download</v-icon>
                  </template>
                  <span>{{$t("_file_share.Download")}}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.fileOwner">
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="shareFilePopup(item)" v-on="on">mdi-share-variant</v-icon>
                  </template>
                  <span>{{$t("_file_share.Share")}}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!item.fileOwner">
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="stopFileShareWithMe(item)" v-on="on">stop_screen_share</v-icon>
                  </template>
                  <span>{{$t("_file_share.Stop_sharing_this_file_with_me")}}</span>
                </v-tooltip>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>


      <v-layout>
        <v-flex xs3 class="my-2">

        </v-flex>
        <v-flex xs6 class="my-2 px-2">

        </v-flex>
        <v-flex xs3 class="my-2">

        </v-flex>
      </v-layout>
    </v-container>
    <v-footer v-if="isRegisteredUser">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="stopFileShare" v-on="on" class="mr-2" color="primary">{{ $t('_file_share.Stop_File_Sharing') }}</v-btn>
        </template>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="shareFilePopup(item)" v-on="on" color="success">{{ $t('_file_share.Share_Files') }} </v-btn>
        </template>
        <span>{{ $t('_file_share.Share_Files') }}</span>
      </v-tooltip>
    </v-footer>

<!--    <vs-prompt-->
<!--      class="calendar-event-dialog"-->
<!--      title="Stop File Sharing"-->
<!--      @accept="stopFileShare"-->
<!--      :accept-text="'Get Shared Users'"-->
<!--      :active.sync="activePromptStopFileSharing"-->
<!--    >-->
<!--      <v-row>-->
<!--        <v-col cols="12" sm="12" md="12" class="my-2 px-1">-->
<!--          <v-card>-->
<!--            <v-card-title>Shared Files</v-card-title>-->
<!--            <v-data-table-->
<!--              :headers="stopSharedFileHeaders"-->
<!--              v-model="selectedSharedFiles"-->
<!--              :items="sharedFiles"-->
<!--              ref="sortableAll"-->
<!--              item-key="id"-->
<!--              show-select-->
<!--              height="573"-->
<!--            ></v-data-table>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </vs-prompt>-->

    <vs-prompt
      class="calendar-event-dialog"
      :title='this.$t("_file_share.Shared_Users")'
      @accept="stopFileShareWithUserInfo"
      :accept-text= 'this.$t("_file_share.Stop_File_Share")'
      :active.sync="activePromptSharedUsers"
    >
      <v-row>
        <v-col cols="12" sm="12" md="12" class="my-2 px-1">
          <v-card>
            <v-card-title>{{ $t('_file_share.Available_users') }}</v-card-title>
            <v-data-table
              :headers="fileSharedUsersHeaders"
              v-model="selectedFileSharedUsers"
              :items="fileSharedUsers"
              ref="sortableAll"
              item-key="Id"
              show-select
              height="450"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </vs-prompt>

    <vs-prompt
      class="calendar-event-dialog"
      :title='this.$t("_file_share.Share_File")'
      @accept="fileShare"
      :accept-text='this.$t("_file_share.Share_File")'
      :active.sync="activePromptShareFile"
    >
      <v-row>
        <v-col cols="12" sm="12" md="12" class="my-2 px-1">
          <v-card>
            <v-card-title>{{ $t('_file_share.Available_users') }}</v-card-title>
            <v-sheet class="px-4 pt-2">
              <v-text-field
                v-model="usersWillShare"
                v-model.lazy="searchFileShareUsers"
                :append-outer-icon="'search'"
                @click:append-outer="searchUsersToShare"
                :label='this.$t("_file_share.Search_User")'
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                v-on:keyup.enter="searchUsersToShare()"
              ></v-text-field>
            </v-sheet>
            <v-data-table
              :headers="shareFileHeaders"
              v-model="selectedUsers"
              :items="intersectUsers"
              ref="sortableAll"
              item-key="Id"
              :search="searchFileShareUsers"
              show-select
              height="450"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </vs-prompt>

    <v-dialog v-model="card_header_props.helper.page_helper" width="500" :value="activePromptRegister">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form') }}</v-card-title>

        <v-card-text>{{ this.$t('_file_share._info.content') }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="register">{{ this.$t('_common.OK') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <vs-prompt
      class="calendar-event-dialog"
      :title='this.$t("_file_share.Register")'
      @accept="register"
      :accept-text='this.$t("_file_share.Register")'
      :active.sync="activePromptRegister"
    >
      <v-row>
        <v-col cols="12" sm="12" class="my-2 px-1">
          <p>{{ $t('_file_share.content1') }}<br>
            {{ $t('_file_share.content2') }}<br>
            {{ $t('_file_share.content3') }}</p>
          <p style="color: red;">{{ $t('_file_share.content4') }}</p>

          <v-form lazy-validation v-model="valid" ref="form">
            <v-text-field
              v-model="password"
              :rules="validations.passwordRules"
              type="password"
              :label='this.$t("_file_share.Enter_Password")'
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <v-text-field
              v-model="passwordConfirm"
              :rules="validations.passwordConfirmRules"
              type="password"
              :label='this.$t("_file_share.Confirm_Password")'
              clearables
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-form>

        </v-col>
      </v-row>
    </vs-prompt>

    <vs-prompt
      class="calendar-event-dialog"
      :title='this.$t("_file_share.Password")'
      @accept="enterPassword(formId)"
      :accept-text='this.$t("_file_share.Password")'
      :active.sync="activePromptPassword"
    >
      <v-row>
        <v-col cols="12" sm="12" class="my-2 px-1">
          <p>{{ this.$t('_file_share.Please_enter_your_password') }}.</p>
          <v-text-field
            v-model="password"
            type="password"
            :label='this.$t("_file_share.Enter_Password")'
            flat
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-col>
      </v-row>
    </vs-prompt>
  </v-card>

</template>

<script>
  import VueFlashcard from "vue-flashcard";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";

  require("../../assets/css/neo.css");

  export default {
    data() {
      return {
        progress: 0,
        activePromptShareFile: false,
        activePromptPassword: false,
        activePromptRegister: false,
        activePromptStopFileSharing: false,
        activePromptSharedUsers: false,
        valid: true,
        validations: {
          passwordRules: this.$goc.validate.create().required().min(6).max(15),
          passwordConfirmRules: this.$goc.validate.create().required().min(6).max(15),
          createFolderRules: this.$goc.validate.create().required().min(3).max(100),
        },

        keyValue: {
          id: null,
          keyTxt: "",
          valueTxt: "",
          topic: ""
        },
        newFolder: {
          FolderName: "",
          UserId: JSON.parse(localStorage.getItem("userInfo")).Uid
        },
        searchFileShareUsers: "",
        usersWillShare: "",
        searchWord: "",
        take: 0,
        skip: 0,
        folder: 0,
        parentId: 0,
        visible: false,
        searchResults: [],
        headers: [
          {
            text: this.$t('_file_share.Shared'),
            sortable: false,
            value: "fileOwner"
          },
          {
            text: this.$t('_file_share.File_Name'),
            align: "left",
            sortable: false,
            value: "name"
          },
          {
            text: this.$t('_file_share.Size') +" (bytes)",
            align: "left",
            sortable: false,
            value: "size"
          },
          {text: this.$t('_file_share.Actions'), value: "action", sortable: false}
        ],

        shareFileHeaders: [
          {
            text: this.$t('_file_share.User_Name'),
            align: "left",
            sortable: false,
            value: "UserName"
          },
        ],
        stopSharedFileHeaders: [
          {
            text: this.$t('_file_share.File_Name'),
            align: "left",
            sortable: false,
            value: "name"
          },
        ],
        fileSharedUsersHeaders: [
          {
            text: this.$t('_file_share.User_Name'),
            align: "left",
            sortable: false,
            value: "UserName"
          },
        ],
        cardStep: 0,
        formId: 0,
        isRegisteredUser: false,
        selectedFolder: null,
        downloadFileId: 0,
        password: "",
        passwordConfirm: "",
        addFolderDialog: false,
        selectedSharedFiles: [],
        selectedFileSharedUsers: [],
        sharedFiles: [],
        fileDataModel: [],
        fileSharedUsers: [],
        xperdriveUsers: [],
        lmsUsers: [],
        intersectUsers: [],
        selectedUsers: [],
        sharedFileId: 0,
        treeFileData: [],
        treeFileDataModel: [],
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.2
        },
        options: {
          pagination: {
            page: 1, // skip
            itemsPerPage: 10, //take
            pageCount: 0,
            rowsPerPageItems: [10, 20, 30, 40],
            footerProps: {"items-per-page-options": [5, 10, 15, 20]}
          }
        },

        tableLoading: false,
        totalItems: 0,

        search: null,
        card_header_props: {
          header: {
            headLine: this.$t('_file_share.File_Share'),
            subTitle: this.$t('_file_share.File_sharing'),
            icon: "mdi-plus-box"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        },
        file: null,
      };
    },

    methods: {
      clearForm() {
        this.keyValue.id = null;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      },
      customRowClass (owner) {
        return owner ? 'owner-bg' : 'not-owner-bg'
      },
      base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
      },
      saveByteArray(reportName, byte, mimeType) {
        var blob = new Blob([byte], {type: mimeType});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
      },

      downloadFile(item) {
        this.activePromptPassword = true;
        this.formId = 1;
        this.downloadFileId = item.id;
      },
      enterPassword(formId) {
        if (formId === 1)
          this.downloadFileWithEnteredPassword();

        if (formId === 2)
          this.shareFileWithEnteredPassword();
      },

      stopFileShareWithUserInfo() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        let requestBody = {
          fileIds: this.fileDataModel.map(a=>a.id),
          userId: uid,
          sharedUserIds: this.selectedFileSharedUsers.map(a=>a.Id)
        };

        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.StopFileSharing, requestBody, response => {
            this.$goc.notify.success({
              title: this.$t('_common.Success'),
              message: this.$t('_file_share.File_Sharing_Stopped')
            })
          this.getFiles()
        })
      },
      downloadFileWithEnteredPassword() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        let requestBody = {
          fileId: this.downloadFileId,
          userId: uid,
          userPassword: this.password
        };

        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.FileDownload, requestBody, response => {
          if (response.stream) {
            var sampleArr = this.base64ToArrayBuffer(response.stream);
            this.saveByteArray("Xperdrive-File", sampleArr, response.mimeType);
          } else {
            this.$goc.notify.error({
              title: this.$t('_common.Failed'),
              message: response.response
            })
          }
        })
      },

      shareFileWithEnteredPassword() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        if (this.sharedFileId.length == 0)
          return;
        let sharedUsersIds = this.selectedUsers.map(a => a.Id);

        let shareBody = {
          userId: uid,
          userPassword: this.password,
          fileIds: this.sharedFileId,
          sharedUserIds: sharedUsersIds
        };
        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.FileShare,
          shareBody,
          response => {
            if (!response.isSuccess) {
              this.$goc.notify.error({
                title: this.$t('_common.Failed'),
                message: response.response
              })
            } else {
              this.$goc.notify.success({
                title: this.$t('_common.Success'),
                message: this.$t('_file_share.File_successfully_shared')
              })
              this.getFiles()
            }
          })
      },
      validate() {
        this.$goc.console.log(this.keyValue.topic);
        if (this.keyValue.topic) return !!this.$refs.form.validate();
        else {
          this.$goc.notify.error({message: "Please select a topic."});
          return false;
        }
      },
      searchFile(itemId) {

        if (!itemId)
          itemId = this.folder;
        if (
          (this.searchWord !== "" && this.searchWord.length >= 3) ||
          this.folder > 0
        ) {
          this.skip = this.options.pagination.page - 1;
          this.take = this.options.pagination.itemsPerPage;

          // var searchBody = {
          //   Text: this.searchWord,
          //   Take: this.take,
          //   Skip: this.skip,
          //   Topic: this.file
          // };
          let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
          var searchBody = {
            fileName: this.searchWord,
            folderId: itemId,
            userId: uid
          }
          let me = this;
          this.$goc.request.postFileShare(
            this.$enums.FILE_SHARE_API.FileSearch,
            searchBody,
            response => {
              if (response.result === undefined) {
                this.visible = false;
                return;
              }

              if (response.result.length > 0) {
                this.totalItems = response.result.length;
                this.searchResults = response.result;
                this.visible = true;
              } else {
                this.searchResults = [];
                this.visible = false;
                me.$goc.notify.info({
                  title: this.$t('_file_share.No_Data'),
                  message: this.$t('_file_share.File_was_not_found')
                });
              }
            }
          );
        } else {
          this.getFiles();
        }
      },
      stopFileShareWithMe(item){
        this.$goc.notify.confirm(
          {
            title: this.$t('_common.AreYouSure'),
            message:this.$t('_file_share.Selected_file_will_be_removed_from_you')
          },
          res => {
            if (res) {
              let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
              let requestBody = {
                UserId: uid,
                FileId: item.id,
              };
              this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.StopSharingFileWithMe, requestBody, response => {
                this.$goc.notify.success({
                  title: this.$t('_common.Success'),
                  message: this.$t('_file_share.File_successfuly_removed_from_you')
                });
                this.getFiles();
              })
            }
          }
        );
      },
      rowClick(selectedItem) {
        this.cardStep = selectedItem.index - 1;
      },
      dialogNotify(title, message, notifyColor) {
        this.$vs.notify({
          title: title,
          text: message,
          color: notifyColor
        });
      },
      createFolder() {
        let selectedFolder = this.selectedFolder ? this.selectedFolder : null;


        if (selectedFolder) {
          this.newFolder.ParentFolderId = selectedFolder.id;
        }

        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.CreateFolder, this.newFolder, response => {
          this.$goc.notify.success({
            title: this.$t('_common.Success'),
            message: this.$t('_file_share.Folder_successfuly_created')
          });
          this.getFolders();
        })
      },
      shareFilePopup(item) {
        if(this.fileDataModel.length == 0)
        {
          this.$goc.notify.warning({
            title: this.$t('_common.Warning'),
            message: this.$t('_file_share.Please_select_a_file')
          });
          return;
        }

        let itemRefused = [];
        let itemList = [];
        if (!item) {
          for (let i = 0; i < this.fileDataModel.length; i++) {
            if (this.fileDataModel[i].fileOwner) {
              itemList.push(this.fileDataModel[i].id);
            } else {
              itemRefused.push(this.fileDataModel[i]);
            }
          }
        }
        else {
          if(item.fileOwner) {
            itemList.push(item.id);
          } else {
            itemRefused.push(item);
          }
        }

        if(itemRefused.length > 0) {
          this.$goc.notify.warning({
            title: this.$t('_common.Warning'),
            message: "This files are not shareable: <br> " + itemRefused.map(a=>a.name).join(", <br>")
          });
        }
        this.sharedFileId = itemList;
        this.getUsers();
        this.activePromptShareFile = true;
      },
      // stopFileSharingPopup() {
      //   this.getSharedFiles();
      //   this.activePromptStopFileSharing = true;
      // },
      getSharedFiles() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        this.$goc.request.getFileShare(this.$enums.FILE_SHARE_API.GetSharedFiles + `?userId=${uid}`, response => {
          this.sharedFiles = response.result;
        })
      },
      fileShare() {
        this.activePromptPassword = true;
        this.formId = 2;
      },
      stopFileShare() {
        this.getFileSharedUsers(this.fileDataModel);
      },
      getFileSharedUsers(item) {
        if(this.fileDataModel.length == 0)
        {
          this.$goc.notify.warning({
            title: this.$t('_common.Warning'),
            message: this.$t('_file_share.Please_select_a_file')
          });
          return;
        }
        var fileIds = item.map(a => a.id);
        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.GetFileSharedUsers, fileIds, response => {
          let users = response.result;
          this.fileSharedUsers = [];
          for(let i = 0; i < users.length; i++) {
            this.getLmsUser(users[i]);
          };
          this.activePromptSharedUsers = true;
        })
      },
      removeFolder() {
        this.$goc.notify.confirm(
          {
            title: this.$t('_common.AreYouSure'),
            message: this.$t('_file_share.Selected_folder_will_be_removed')
          },
          res => {
            if (res) {
              let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
              let deleteFolderBody = {
                UserId: uid,
                FolderId: this.selectedFolder.id,
                ForceDelete: true
              };
              this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.DeleteFolder, deleteFolderBody, response => {
                this.$goc.notify.success({
                  title: this.$t('_common.Success'),
                  message: this.$t('_file_share.Folder_successfuly_deleted')
                });
                this.getFolders();
                this.getFiles();
              })
            }
          }
        );

      },
      getUsers() {
        this.$goc.request.getFileShare(this.$enums.FILE_SHARE_API.GetUsers, response => {
          this.xperdriveUsers = response.result;
          this.getLmsUsers();
        })
      },
      getLmsUser(userId) {
        /**
         * TODO : userId'ler tek tek çağırılmadan liste halinde tek seferde çağırılacak. LMS backend'de geliştirmeler yapılacak.
         */
        let _me = this;
        this.$goc.request.get(
          this.$enums.API.GetUser + userId,
          {
            then: response => {
              _me.fileSharedUsers.push(response.Result.User);
            }
          }
        );
      },
      getLmsUsers() {
        let userBody = {
          UserName: "",
          FirstName: "",
          LastName: "",
          UserRole: 0,
          Email: ""
        };
        this.$goc.request.post(
          this.$enums.API.GetUsers,
          {userBody},
          {
            then: response => {
              this.lmsUsers = response.Result.Users;
              let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
              let intersectIds = this.lmsUsers.map(a => a.Id).filter(value => -1 !== this.xperdriveUsers.indexOf(value));
              var index = intersectIds.indexOf(uid);
              if (index !== -1) intersectIds.splice(index, 1);
              this.getIntersectUsers(intersectIds);
            }
          }
        );
      },
      getIntersectUsers(intersectIds) {
        this.intersectUsers = [];
        for (var i = 0; i < this.lmsUsers.length; i++) {
          if (intersectIds.includes(this.lmsUsers[i].Id)) {
            this.intersectUsers.push(this.lmsUsers[i])
          }
        }
      },
      getFolders() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        this.$goc.request.getFileShare(this.$enums.FILE_SHARE_API.GetFolders + `?userId=${uid}`, response => {
          this.treeFileData = response.result;
        })
      },
      searchUsersToShare() {

      },
      uploadFile() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        let folderId = 0;
        if (this.folder)
          folderId = this.folder;

        for(let i = 0; i<this.file.length; i++)
        {
          let formData = new FormData();
          formData.append("formFile", this.file[i]);
          this.$goc.request.postFileShareMultipart(
            this.$enums.FILE_SHARE_API.FileUpload + `?userId=${uid}&folderId=${folderId}`,
            formData,
            response => {
              this.progress = 0;
            this.$goc.notify.success({
              title: this.$t('_common.Success'),
              message: this.$t('_file_share.File_successfuly_uploaded')
            });
            this.getFolders();
            this.getFolderFiles(response.result);
          },
           {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
            }
           }
          )
        }
      },
      getFolderFiles(folderId) {
        // this.skip = this.options.pagination.page - 1;
        // this.take = this.options.pagination.itemsPerPage;
        //
        // var searchBody = {
        //   Text: this.searchWord,
        //   Take: this.take,
        //   Skip: this.skip,
        //   Topic: this.topic
        // };
        // let me = this;
        if (this.folder)
          folderId = this.folder;
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        this.$goc.request.getFileShare(
          this.$enums.FILE_SHARE_API.GetFolderFiles + `?folderId=${folderId}&userId=${uid}`,
          response => {
            if (response.result === undefined) {
              this.visible = false;
              return;
            }

            if (response.result.length > 0) {
              this.totalItems = response.result.length;
              this.searchResults = response.result;
              this.visible = true;
            } else {
              this.searchResults = [];
              this.visible = false;
              this.$goc.notify.info({
                title:  this.$t('_file_share.No_Data'),
                message: this.$t('_file_share.File_was_not_found')
              });
            }
          }
        );
      },
      getFiles() {
        // this.skip = this.options.pagination.page - 1;
        // this.take = this.options.pagination.itemsPerPage;
        //
        // var searchBody = {
        //   Text: this.searchWord,
        //   Take: this.take,
        //   Skip: this.skip,
        //   Topic: this.topic
        // };
        // let me = this;
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        this.$goc.request.getFileShare(
          this.$enums.FILE_SHARE_API.GetFiles + `?userId=${uid}`,
          response => {
            if (response.result === undefined) {
              this.visible = false;
              return;
            }
            if (response.result.length > 0) {
              this.totalItems = response.result.length;
              this.searchResults = response.result;
              this.visible = true;
            } else {
              this.searchResults = [];
              this.visible = false;
              this.$goc.notify.info({
                title: this.$t('_file_share.No_Data'),
                message: this.$t('_file_share.File_was_not_found')
              });
            }
          }
        );
      },
      removeFile(item) {
        this.$goc.notify.confirm(
          {
            title: this.$t('_common.AreYouSure'),
            message: this.$t('_file_share.Selected_file_will_be_removed')
          },
          res => {
            if (res) {
              let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
              let deleteBody = {
                UserId: uid,
                FileId: item.id,
                ForceDelete: true
              };
              this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.DeleteFile, deleteBody, response => {
                if (response.isSuccess) {
                  this.$goc.notify.success({
                    title: this.$t('_common.Success'),
                    message: this.$t('_file_share.File_successfuly_deleted')
                  });
                } else {
                  this.$goc.notify.warning({
                    title: this.$t('_common.Warning'),
                    message: response.result
                  });
                }

                this.getFiles();
              })
            }
          }
        );
      },
      init() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;

        this.$goc.request.getFileShare(this.$enums.FILE_SHARE_API.IsRegisteredUser + `?userId=${uid}`, response => {
          this.isRegisteredUser = response.result;

          if (this.isRegisteredUser) {
            this.getFiles();
            this.getFolders();
          } else {
            this.activePromptRegister = true;
          }
        })
      },
      register() {
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        if (this.password != this.passwordConfirm)
          return;

        let registerBody = {
          userId: uid,
          userPassword: this.password
        };

        this.$goc.request.postFileShare(this.$enums.FILE_SHARE_API.Register, registerBody, response => {
          this.isRegisteredUser = response.result;
          window.location.reload();
        })
      },
      onSelectedFile(item) {

        if (item.length > 0) this.keyValue.topic = item[0];
        else this.keyValue.topic = "";
        if(item[0])
          this.selectedFolder = this.$refs.treeViewFolders.nodes[item].item;
        // this.selected = this.treeFileData[item[0]-1].id;
        this.selected = item[0];
        this.treeFileDataModel = item;
        this.searchWord = "";
        this.skip = 0;
        this.take = 0;
        this.folder = item[0];
        this.searchFile(item[0]);
      },
      updatePage(page) {
        this.$goc.console.log(page);
        this.$goc.console.log(this.options);
        this.searchFile();
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.searchWord != "";
      },
      indexedItems() {
        return this.searchResults.map((item, index) => ({
          index: index + 1,
          ...item
        }));
      }
    },
    mounted: function () {
      this.init();
    },

    components: {
      VueFlashcard,
      VuePerfectScrollbar
    }
  };
</script>

<style>
  .not-owner-bg {
    background-color: orangered !important;
  }
  .owner-bg {
    background-color: #2c78e4 !important;
  }
  .animated.flipInX.animated {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    border-radius: 7px;
  }

  .animated.flipInX.animated:hover {
    box-shadow: 1px 4px 25px 7px rgba(0, 0, 0, 0.4);
    margin: 0;
    border-radius: 7px;
  }

  .animated.flipInX.flashcard {
    height: 100%;
  }

  .card-header {
    margin-top: 17%;
  }

  .v-data-table.elevation-1.theme--light:hover {
    cursor: pointer;
  }
</style>
